import {Loader} from "@googlemaps/js-api-loader";


class MapManager {
    getDto() {
        let mapDto = {
            elementId: 'mapDev',
            center: {lat: 37.7749, lng: -122.4194},
            zoom: 12,
            mapInstance: null, // Initialement null, sera mis à jour par addGoogleMap
            markers: {},
            polylines: {},
            moveCounts: {}, // C
            mapApiKey: ""// Initialement vide, sera mis à jour par addMarker
        };
        return mapDto;
    }

    // Méthode pour ajouter une carte Google Maps dans un élément du DOM avec un callback de clic sur la carte
    async addGoogleMap(mapDto, mapClickCallback = null) {
        const {elementId} = mapDto;
        const element = document.getElementById(elementId);
        if (!element) {
            throw new Error(`Element avec l'id "${elementId}" non trouvé.`);
        }

        let loader = new Loader({apiKey: mapDto.mapApiKey})
        await loader.load()
        mapDto.mapInstance = new google.maps.Map(document.getElementById(elementId), {
            center: mapDto.center || {lat: -34.397, lng: 150.644},
            zoom: mapDto.zoom || 8,
            mapTypeId: 'hybrid'
        })

        if (mapClickCallback) {
            mapDto.mapInstance.addListener('click', (event) => {
                mapClickCallback(event);
            });
        }

        mapDto.markers = mapDto.markers || {};
        mapDto.polylines = mapDto.polylines || {};
        mapDto.moveCounts = mapDto.moveCounts || {}; // Compteur de mouvements pour chaque marqueur
        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour mettre à jour les configurations de la carte Google Maps
    updateMapConfig(mapDto) {
        if (!mapDto.mapInstance) {
            throw new Error("La carte Google n'est pas initialisée. Appelez d'abord addGoogleMap.");
        }

        const {center, zoom} = mapDto;

        if (center) {
            mapDto.mapInstance.setCenter(center);
        }

        if (zoom) {
            mapDto.mapInstance.setZoom(zoom);
        }

        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour ajouter un élément (marker) sur la carte à une position donnée avec un callback de clic
    addMarker(mapDto, position, title = '', clickCallback = null) {
        if (!mapDto.mapInstance) {
            throw new Error("La carte Google n'est pas initialisée. Appelez d'abord addGoogleMap.");
        }

        const marker = new google.maps.Marker({
            position,
            map: mapDto.mapInstance,
            title,
            draggable: true, // Permet au marqueur d'être déplacé
        });

        if (clickCallback) {
            marker.addListener('click', () => {
                clickCallback(marker);
            });
        }

        mapDto.markers[title] = marker;
        mapDto.moveCounts[title] = 0; // Initialiser le compteur de mouvements
        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour ajouter des éléments HTML personnalisés comme marqueurs
    addCustomHtmlMarker(mapDto, position, htmlContent, title = '') {
        if (!mapDto.mapInstance) {
            throw new Error("La carte Google n'est pas initialisée. Appelez d'abord addGoogleMap.");
        }

        const customMarker = new google.maps.OverlayView();
        customMarker.onAdd = function () {
            const div = document.createElement('div');
            div.innerHTML = htmlContent;
            div.style.position = 'absolute';
            customMarker.div = div;
            const panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        };

        customMarker.draw = function () {
            const overlayProjection = this.getProjection();
            const positionLatLng = new google.maps.LatLng(position.lat, position.lng);
            const positionPixels = overlayProjection.fromLatLngToDivPixel(positionLatLng);

            const div = customMarker.div;
            div.style.left = positionPixels.x + 'px';
            div.style.top = positionPixels.y + 'px';
        };

        customMarker.onRemove = function () {
            customMarker.div.parentNode.removeChild(customMarker.div);
            customMarker.div = null;
        };

        customMarker.position = new google.maps.LatLng(position.lat, position.lng); // Ajout de la position initiale
        customMarker.setMap(mapDto.mapInstance);
        mapDto.markers[title] = customMarker;
        mapDto.moveCounts[title] = 0; // Initialiser le compteur de mouvements pour les marqueurs HTML
        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour modifier la position d'un marker avec un effet de glissement et une traînée visible
    moveMarker(mapDto, title, newPosition) {
        const marker = mapDto.markers[title];
        if (!marker) {
            throw new Error(`Marqueur avec le titre "${title}" non trouvé.`);
        }

        const path = [marker.getPosition ? marker.getPosition() : marker.position];
        const deltaLat = (newPosition.lat - path[0].lat()) / 100;
        const deltaLng = (newPosition.lng - path[0].lng()) / 100;

        let i = 0;
        const interval = setInterval(() => {
            if (i < 100) {
                const currentPos = marker.getPosition ? marker.getPosition() : marker.position;
                const lat = currentPos.lat() + deltaLat;
                const lng = currentPos.lng() + deltaLng;
                const nextPos = new google.maps.LatLng(lat, lng);

                if (marker.setPosition) {
                    marker.setPosition(nextPos);
                } else {
                    marker.position = nextPos;
                    marker.draw();
                }

                path.push(nextPos);
                i++;
            } else {
                clearInterval(interval);
                if (marker.setPosition) {
                    marker.setPosition(newPosition);
                } else {
                    marker.position = newPosition;
                    marker.draw();
                }
                path.push(newPosition);

                // Incrémenter le compteur de mouvements
                mapDto.moveCounts[title] += 1;

                // Supprimer la ligne après 3 mouvements
                if (mapDto.moveCounts[title] >= 3) {
                    if (mapDto.polylines[title]) {
                        mapDto.polylines[title].setMap(null);
                        delete mapDto.polylines[title];
                    }
                    mapDto.moveCounts[title] = 0; // Réinitialiser le compteur de mouvements
                }
            }

            if (!mapDto.polylines[title]) {
                mapDto.polylines[title] = new google.maps.Polyline({
                    map: mapDto.mapInstance,
                    path: path,
                    strokeColor: '#FF0000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });
            } else {
                mapDto.polylines[title].setPath(path);
            }
        }, 10);

        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour modifier l'icône d'un marker en particulier
    updateMarkerIcon(mapDto, title, iconUrl) {
        const marker = mapDto.markers[title];
        if (!marker) {
            throw new Error(`Marqueur avec le titre "${title}" non trouvé.`);
        }

        marker.setIcon({
            url: iconUrl,
            scaledSize: new google.maps.Size(50, 50), // Ajustez la taille de l'icône si nécessaire
        });

        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour modifier l'icône d'un marker en particulier pour un GIF
    updateMarkerIconToGif(mapDto, title, gifUrl) {
        const marker = mapDto.markers[title];
        if (!marker) {
            throw new Error(`Marqueur avec le titre "${title}" non trouvé.`);
        }

        marker.setIcon({
            url: gifUrl,
            scaledSize: new google.maps.Size(50, 50), // Ajustez la taille du GIF si nécessaire
        });

        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour mettre à jour le contenu HTML d'un marqueur personnalisé
    updateCustomHtmlMarkerContent(mapDto, title, newHtmlContent) {
        const customMarker = mapDto.markers[title];
        if (!customMarker || !customMarker.div) {
            throw new Error(`Marqueur avec le titre "${title}" non trouvé.`);
        }

        customMarker.div.innerHTML = newHtmlContent;
        this.dto = mapDto;
        return this.dto;
    }

    showAllMarkers(mapDto) {
        Object.keys(mapDto.markers).forEach(title => {
            mapDto.markers[title].setMap(mapDto.mapInstance);
        });
        this.dto = mapDto;
        return this.dto;
    }

    hideMarkersExcept(mapDto, titlesToShow) {
        Object.keys(mapDto.markers).forEach(title => {
            if (!titlesToShow.includes(title)) {
                mapDto.markers[title].setMap(null);
            }
        });
        this.dto = mapDto;
        return this.dto;
    }

    // Méthode pour obtenir le DTO actuel

}


export default MapManager
